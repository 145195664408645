import type { ReactNode, Context, RefObject } from 'react'
import type { PageType } from '../types/page'

import { createContext, useState, useRef, useEffect, useContext } from 'react'
import { useAmp } from 'next/amp'
import { mixins, theme } from '../styles'
import { SVGIcon } from '../components/atoms/SVGIcon'
import { PAGE_TYPE_CONTENT } from '../constants/page'
import { TF1InfoLogo } from '../components/atoms/Icon/TF1InfoLogo'
import { TF1PlusLogo } from '../components/atoms/Icon/TF1PlusLogo'
import { TagCommanderContext } from './tagcommander'

export interface IModal {
  setModal: ({
    ref,
    text,
    src,
    linkRegistration,
    linkLogin,
    tmsClose,
    tmsLogin,
    tmsRegistration,
  }: {
    ref?: RefObject<HTMLElement>
    text?: string
    src?: string
    linkRegistration?: {
      label: string
      href: string
    }
    linkLogin?: {
      label: string
      href: string
    }
    tmsClose?: string
    tmsLogin?: string
    tmsRegistration?: string
  }) => void
}

export type IModalContext = Context<IModal>

export const ModalContext: IModalContext = createContext({
  setModal: () => null,
})

export function ModalProvider({ children }: { children?: ReactNode }): JSX.Element {
  const [isVisible, setIsVisibleModal] = useState(false)
  const [text, setText] = useState(null)
  const [src, setSrc] = useState(null)
  const [tmsClose, setTmsClose] = useState(null)
  const [tmsLogin, setTmsLogin] = useState(null)
  const [tmsRegistration, setTmsRegistration] = useState(null)
  const [linkRegistration, setLinkRegistration] = useState({ label: null, href: '#' })
  const [linkLogin, setLinkLogin] = useState({ label: null, href: '#' })
  const refTargetInitial = useRef(null)
  const modalRef = useRef(null)
  const modalRefContent = useRef(null)
  const { hit } = useContext(TagCommanderContext)

  const onErrorLoadImage = () => setSrc(null)
  const handleOnClick = ({ noTms = false }) => {
    if (!noTms) hit({ screen_clickableElementName: tmsClose }, { isClickEvent: true })
    setIsVisibleModal(false)
    //Accessibility: Focus on element who has open the modal
    refTargetInitial.current?.focus?.({ preventScroll: false })
    document.removeEventListener('keydown', handleKeydown)
  }

  const handleOnClickLogin = () => {
    hit({ screen_clickableElementName: tmsLogin }, { isClickEvent: true })
  }

  const handleOnClickRegistration = () => {
    hit({ screen_clickableElementName: tmsRegistration }, { isClickEvent: true })
  }

  //Accessibility: Block Tab in modal
  const handleKeydown = (e) => {
    if (modalRef.current && modalRefContent.current) {
      const children = Array.from([
        ...modalRef.current.children,
        ...modalRefContent.current.children,
      ])
      const firstFocusableElement = children[0]
      const lastFocusableElement = children[children.length - 1]
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9
      const isEscapePressed = e.key === 'Escape' || e.keyCode === 27
      if (isEscapePressed) {
        handleOnClick({ noTms: true })
        return
      }

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus({ preventScroll: false }) // add focus for the last focusable element
          e.preventDefault()
        }
      } else {
        // if tab key is pressed
        if (
          document.activeElement === lastFocusableElement ||
          !children.includes(document.activeElement)
        ) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus({ preventScroll: false }) // add focus for the first focusable element
          e.preventDefault()
        }
      }
    }
  }

  const setModal = ({
    text,
    src,
    linkRegistration,
    linkLogin,
    ref,
    tmsClose,
    tmsLogin,
    tmsRegistration,
  }) => {
    setIsVisibleModal(true)
    setLinkLogin(linkLogin)
    setLinkRegistration(linkRegistration)
    setText(text)
    setSrc(src)
    setTmsClose(tmsClose)
    setTmsLogin(tmsLogin)
    setTmsRegistration(tmsRegistration)
    modalRef.current?.focus?.({ preventScroll: false })
    refTargetInitial.current = ref?.current
    document.addEventListener('keydown', handleKeydown)
  }

  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  return (
    <>
      <ModalContext.Provider
        value={{
          setModal,
        }}
      >
        {isVisible && (
          <>
            <div
              className="Modal"
              ref={modalRef}
              role="dialog"
              aria-modal="true"
              aria-labelledby={text ? 'modal-heading' : undefined}
            >
              <>
                <button onClick={() => handleOnClick({ noTms: false })} title="Fermer la modale">
                  <SVGIcon
                    name="close"
                    size={30}
                    primaryColor={src ? theme.cssVars.white : theme.cssVars.darkBlue}
                  />
                </button>
                {src && (
                  <img alt="" className="Modal__Image" src={src} onError={onErrorLoadImage} />
                )}
                <div className="Modal__Content" ref={modalRefContent}>
                  {text && <h1 id="modal-heading">{text}</h1>}
                  <p className="flex">
                    <TF1InfoLogo theme="blue" size={124} />
                    ou
                    <TF1PlusLogo color={theme.cssVars.darkestBlue} size={124} />
                  </p>
                  {linkLogin?.label && (
                    <a
                      className="Modal__Content__buttonLogin flex"
                      href={linkLogin?.href}
                      onClick={handleOnClickLogin}
                    >
                      {linkLogin.label}
                    </a>
                  )}
                  {linkRegistration?.label && (
                    <a
                      className="Modal__Content__buttonRegistration flex"
                      href={linkRegistration?.href}
                      onClick={handleOnClickRegistration}
                    >
                      {linkRegistration.label}
                    </a>
                  )}
                </div>
              </>
            </div>
            <div className="Modal__Backdrop" onClick={() => handleOnClick({ noTms: true })} />
          </>
        )}
        {children}
      </ModalContext.Provider>
      <style jsx>{`
        @keyframes moveInModal {
          0% {
            transform: translate(-50%, 150vh);
          }
          60% {
            transform: translate(-50%, -50%);
          }
        }

        .Modal__Backdrop {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100vw;
          height: 100vh;
          background-color: ${theme.cssVars.deepBlue};
          opacity: 0.7;
          z-index: 50;
          cursor: pointer;
        }

        .Modal {
          position: fixed;
          justify-content: center;
          align-items: center;
          max-width: 477px;
          width: calc(100% - 60px);
          z-index: 1000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          gap: 30px;
          border-radius: 10px;
          background-color: ${theme.cssVars.white};
          color: ${theme.cssVars.liveProgramBlue};
          box-shadow: 4px 4px 20px 0px rgba(0, 10, 104, 0.1);
          overflow: hidden;
          z-index: 51;
          animation: moveInModal 0.7s ease forwards;
        }

        .Modal__Content {
          padding: 30px;
        }

        .Modal img {
          max-width: inherit;
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .Modal h1,
        .Modal p {
          font-family: ${theme.fonts.overpass};
          color: ${theme.cssVars.deepBlue};
          margin: 0;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 133%;
          text-align: center;
        }

        .Modal p.flex {
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
        }

        .Modal .Modal__Content__buttonLogin,
        .Modal .Modal__Content__buttonRegistration {
          padding: 16px 24px;
          justify-content: center;
          border-radius: 4px;
          text-align: center;
          font-family: ${theme.fonts.overpass};
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 141%;
        }

        .Modal .Modal__Content__buttonLogin {
          background-color: ${theme.cssVars.deepBlue};
          color: ${theme.cssVars.white};
        }

        .Modal .Modal__Content__buttonRegistration {
          background-color: ${theme.cssVars.white};
          color: ${theme.cssVars.deepBlue};
        }

        .Modal button {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          background-color: transparent;
          border: none;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Modal h1,
          .Modal p {
            font-size: 17.5px;
            line-height: 136%;
          }
        }
      `}</style>
    </>
  )
}

export function ModalProviderWrapper({
  type,
  children,
}: {
  type: PageType
  children?: ReactNode
}): JSX.Element {
  const isAmp = useAmp()
  if (type !== PAGE_TYPE_CONTENT || isAmp) return <>{children}</>

  return <ModalProvider>{children}</ModalProvider>
}
