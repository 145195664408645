import type { BookmarksRouteResponse } from '@etf1-interne/tf1info_types_news/endpoints'
import type { GigyaErrorInfo, GigyaResponse, GigyaUid, UserData } from '../../types/gigya'
import type { User } from '../../types/user'
import type { ITailoredArticleItem } from '../../components/organisms/TailoredArticlesList'
import type { ILocalisedArticle } from '../../components/organisms/LocalisedInfo'
import type { ITmsData, IVideosData } from '../../types/tms'
import type { JTPersoVideoContentList } from '../../components/templates/PlayerJT'
import type { IMultiLiveItem } from '../../components/molecules/MultiLiveItem'
import type { IScoreBoard } from '../../components/molecules/ScoreBoard'
import type { IAcademy, IDegree, IDepartment } from '../../components/molecules/ExamSearch'

export function getAuthHeaders({ user }: { user: GigyaUid }) {
  if (!user?.UID || !user?.UIDSignature || !user?.signatureTimestamp) {
    return {}
  }

  return {
    'X-Gigya-Signature': generateAuthToken(
      user.UID,
      Number(user.signatureTimestamp),
      user.UIDSignature,
    ),
  }
}

export function generateAuthToken(uid: string, timestamp: number, signature: string): string {
  return Buffer.from(JSON.stringify({ uid, timestamp, signature }), 'binary').toString('base64')
}

export async function fetchUnsubscribeNewsletterAccount({
  UID,
  HKEY_ID,
  PAR_ID,
}: {
  UID?: string
  HKEY_ID?: string
  PAR_ID?: string
}): Promise<{ statusCode?: number }> {
  const params = new URLSearchParams({
    UID,
    HKEY_ID,
    PAR_ID,
  })
  const response = await fetch(`/api/unsubscribe/?${params}`)
  const body = await response.json()

  return body
}

export async function fetchDeleteAccount(user: GigyaUid): Promise<GigyaErrorInfo> {
  const response = await fetch(`/api/suppression-compte/`, {
    headers: {
      ...getAuthHeaders({ user }),
    },
  })
  const body = await response.json()
  return body
}

export async function fetchMyNews(
  user: User,
  limit: number,
  hasCustomisationConsent: boolean,
): Promise<{
  myNews?: { elementList: ITailoredArticleItem[] }
  localisedNews?: { title: string; location: string; elementList: ILocalisedArticle[] }
  tms?: { page: ITmsData }
}> {
  const params = new URLSearchParams({
    userId: user.UID,
    favoriteCategories: `${user.data.Tf1Info?.favoriteCategories || ''}`,
    page: '0',
    limit: `${limit}`,
    postcode: user.profile.zip?.substring(0, 5) ?? '',
    persoConsent: hasCustomisationConsent ? '1' : '0',
  })
  const res = await fetch(`/api/pour-vous/?${params}`, {
    headers: {
      ...getAuthHeaders({ user }),
    },
  })
  const body = await res.json()
  return body
}

export async function fetchMyTvNews(
  user: User,
  hasCustomisationConsent: boolean,
): Promise<{
  elementList: JTPersoVideoContentList
  introduction: { videorefId: string }
  tms?: { page: ITmsData; videos: IVideosData }
}> {
  const params = new URLSearchParams({
    userId: user.UID,
    favoriteCategories: `${user.data.Tf1Info?.favoriteCategories || ''}`,
    postcode: user.profile.zip?.substring(0, 5) || '',
    persoConsent: hasCustomisationConsent ? '1' : '0',
  })

  const res = await fetch(`/api/jt-perso/?${params}`, {
    headers: {
      ...getAuthHeaders({ user }),
    },
  })
  const body = await res.json()
  return body
}

export async function fetchNewMultiLivePosts(
  liveId: string,
  dateFrom: string,
): Promise<(IMultiLiveItem | IScoreBoard)[]> {
  const params = new URLSearchParams({
    liveId: liveId,
    date: dateFrom,
  })

  const res = await fetch(`/api/new-posts/?${params}`)
  return await res.json()
}

export async function fetchConfig(): Promise<void> {
  const res = await fetch(`/api/config/`)
  const publicConfig = res.json()

  return publicConfig
}

export function setupGamCookies(user: User | GigyaResponse, toDelete = false): void {
  fetch(`/api/user-setup/?${toDelete ? 'toDelete=true' : ''}`, {
    credentials: 'include',
    headers: {
      ...getAuthHeaders({ user }),
    },
  })
}

export function getPlayerJWT(user: User | GigyaResponse): Promise<string> {
  return fetch(`/api/auth/jwt`, {
    headers: {
      ...getAuthHeaders({ user }),
    },
  })
    .then((res) => res.json())
    .then((data) => data.token)
}

export async function fetchExamData(): Promise<{
  academies: IAcademy[]
  departments: IDepartment[]
  degrees: IDegree[]
}> {
  const response = await fetch('/api/exam-data/')
  const body = await response.json()

  return body
}

export async function fetchProgram(id: string) {
  const params = new URLSearchParams({
    id,
  })

  const response = await fetch(`/api/lives/?${params}`)
  const body = await response.json()

  return body
}

export async function fetchCategories() {
  const response = await fetch(`/api/categories/`)
  return response.json()
}

export async function fetchNextVideos({
  contentId,
  videoId,
  size = 10,
  offset = 0,
  excludedIds = [],
}) {
  const params = new URLSearchParams({
    contentId: `${contentId}`,
    videoId: `${videoId}`,
    size: `${size}`,
    offset: `${offset}`,
    excludedIds: excludedIds.join(','),
  })

  const response = await fetch(`/api/next-videos/?${params}`)
  return response.json()
}

export async function fetchBookmarks({
  contentIds,
  user,
}: {
  contentIds: string[]
  user: GigyaUid
}): Promise<BookmarksRouteResponse> {
  const params = new URLSearchParams({
    contentIds: contentIds.join(','),
  })

  const response = await fetch(`/api/bookmarks/?${params}`, {
    credentials: 'include',
    headers: {
      ...getAuthHeaders({ user }),
    },
  })
  return response.json()
}
