import type { IPageData } from '../../../types/page'
import Router, { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { GOOGLE_AD_MANAGER_SCRIPT_ID, HUBVISOR_SCRIPT_ID } from '../../../constants/scripts'
import { getCookie } from '../../../helpers/cookies'
import { defineSlot, isAdDispayable } from '../../../helpers/gam'
import { useScript } from '../../../hook/useScript'
import { GAM_COOKIE_KEY } from '../../../constants/cookies'
export interface IAd {
  config: IPageData['ads']
}

export interface ITF1AdSlot extends googletag.Slot {
  lazy?: boolean
  timer?: { start: () => void; stop: () => void; reset: () => void }
}

export function AdConfig({ config }: IAd): JSX.Element {
  const { isReady: isRouterReady, query } = useRouter()
  const { isScriptLoaded: isGAMScriptLoaded } = useScript({
    id: GOOGLE_AD_MANAGER_SCRIPT_ID,
  })
  const adSlots = useRef<{ [key: string]: ITF1AdSlot }>({})

  const {
    adSlots: adSlotsConfig,
    enableHubvisor: enableHubvisorConfig,
    lazyload: lazyloadConfig,
    refresh: refreshConfig,
    targets: targetsConfig,
  } = config || {}

  const { isScriptLoaded: isHubvisorLoaded } = useScript({
    id: HUBVISOR_SCRIPT_ID,
    loadOnMount: enableHubvisorConfig,
  })

  function setTransitionStarted() {
    window?.googletag?.cmd?.push(function () {
      googletag.destroySlots()
    })
  }

  function setupAds() {
    const userPPID = getCookie(GAM_COOKIE_KEY)

    window?.googletag?.cmd?.push(function () {
      window?.googletag.setAdIframeTitle('Publicité')
      adSlots.current = adSlotsConfig?.reduce(
        (
          slots,
          { containerId, type, availableFormats, slot: { sizes, refresh, lazyload, targets } },
        ) => {
          if (!isAdDispayable(availableFormats)) {
            return slots
          }
          const path = config.path + '/website/' + type
          const slot = defineSlot(
            path,
            lazyload,
            sizes,
            containerId,
            targets,
            refresh,
            refreshConfig.duration,
          )
          return { ...slots, [containerId]: slot }
        },
        {},
      )

      Object.entries(targetsConfig).forEach(([key, value]) => {
        if (value) {
          window.googletag.pubads().setTargeting(key, value)
        }
      })

      if (query.adtest) {
        window.googletag.pubads().setTargeting('adtest', query.adtest)
      }

      if (refreshConfig?.enable) {
        // AdConfig pushAdsToGam : add listener for slotVisibilityChanged
        window.googletag.pubads().addEventListener('slotVisibilityChanged', function (event) {
          const slotId = event.slot.getSlotElementId()
          if (!adSlots.current[slotId]?.timer) return
          if (event.inViewPercentage > 50) {
            adSlots.current[slotId].timer.start()
          } else {
            adSlots.current[slotId].timer.stop()
          }
        })
      }
      //options GPT
      window.googletag.pubads().setCentering(true)
      window.googletag.pubads().collapseEmptyDivs(true)
      window.googletag.pubads().disableInitialLoad()
      window.googletag.pubads().enableSingleRequest()

      if (userPPID) {
        googletag.pubads().setPublisherProvidedId(userPPID)
      }

      window.googletag.enableServices()
    })
  }

  function refreshAds() {
    window?.googletag?.cmd?.push(function () {
      //si le lazyload est actif
      if (lazyloadConfig.enable) {
        //on ne récupère que les formats non lazyloadés pour le premier batch
        const slotsNoLazy = []
        const slotsLazy = []
        Object.values(adSlots.current).forEach((slot) => {
          slot.lazy ? slotsLazy.push(slot) : slotsNoLazy.push(slot)
        })

        //on lance le rendu des formats
        //si slots est undefined (lazyLoad inactif), on génère tous les formats
        //sinon on prépare le tableau de slots correspondant

        if (slotsNoLazy.length) {
          window.googletag.pubads().refresh(slotsNoLazy)
        }

        //on lance le second bacth SRA pour les slots Lazyloadés
        //lot SRA pour les slots avec lazyload
        window.googletag.pubads().enableLazyLoad({
          fetchMarginPercent: lazyloadConfig.fetchMarginPercent,
          renderMarginPercent: lazyloadConfig.renderMarginPercent,
          mobileScaling: lazyloadConfig.mobileScaling,
        })

        if (slotsLazy.length) {
          window.googletag.pubads().refresh(slotsLazy)
        }

        if (!slotsNoLazy.length && !slotsNoLazy.length) {
          window.googletag.pubads().refresh()
        }
      }
      //si lazyloading inactif, on lance le rendu pour tous les formats
      else {
        window.googletag.pubads().refresh()
      }
    })
  }

  useEffect(() => {
    if (
      isGAMScriptLoaded &&
      isRouterReady &&
      adSlotsConfig?.length &&
      window?.googletag?.defineSlot &&
      ((isHubvisorLoaded && enableHubvisorConfig) || !enableHubvisorConfig)
    ) {
      // Setup slots
      setupAds()
      // call the adserver only when the wrapper is ready
      if (typeof window.Hubvisor === 'function') {
        window.Hubvisor('ready', null, function () {
          // AdConfig Hubvisor : ready callback, call refreshAds() function'
          refreshAds()
        })
      } else {
        // AdConfig Hubvisor : not ready, call refreshAds() function'
        refreshAds()
      }
    }
  }, [isGAMScriptLoaded, isRouterReady, isHubvisorLoaded])

  useEffect(() => {
    Router.events.on('routeChangeStart', setTransitionStarted)
    return () => {
      Router.events.off('routeChangeStart', setTransitionStarted)
    }
  }, [])

  return null
}
